import React from "react";
import { graphql, Link } from "gatsby";
import Footer from "../components/Footer";
import NavBarTwo from "../components/NavbarTwo";
import Image from "gatsby-plugin-sanity-image";
import { SEO } from "../components/seo";
import Topics from "../components/Topics";
import ContactDetails from "../components/ContactDetails";



const ecommerce = ({ data }) => {
  return (
    <>
      <NavBarTwo />


      <section className="bg-hero-e-commerce-lg xl:bg-hero-e-commerce-xl flex flex-col justify-end sm:block pb-16 text-gray-400 bg-gray-900 body-font bg-no-repeat  bg-center	 bg-cover	 h-screen">
        <div className="container  max-w-7xl mx-auto max-w-7xl flex  px-5 sm:py-64 sm:flex-row flex-col items-center">
          <div className="lg:flex-grow max-w-sm md:max-w-lg md:w-1/2 -lg:pr-24 -md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center  bg-dark/40 py-6 px-4 rounded">
            <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
            <h1 className="title-font sm:text-5xl text-3xl mb-4 font-medium text-white">Erfolgswege im
              <br /><span className="text-primary">E-Commerce
              </span>
            </h1>
            <br />
            <p className="text-sm md:text-base mb-8 leading-relaxed text-white max-w-sm">Individuelle E-Commerce Lösungen für KMU. Rundum Beratung und Implementierung für E-Commerce Einstieg und Wachstum.</p>
            <div className="flex-col lg:flex-row justify-center">
              <a href="#main" className=" inline-flex text-white bg-primary border-0 py-2 px-6 focus:outline-none hover:bg-primary rounded-full text-lg mb-6">mehr erfahren
              </a>

              <a href="#contacts" className="sm:ml-0 lg:ml-4  inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-6 focus:outline-none hover:bg-gray-700 hover:text-white rounded-full text-lg">Kontaktaufnahme</a>
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font" id="main">
        <div className="container  max-w-7xl mx-auto flex px-5 py-48 md:flex-row flex-col items-center">
          <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
            <div class="w-12 h-1 bg-primary rounded mt-2 mb-4"></div>
            <h1 className=" title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">E-Commerce Einstieg
              <br className="hidden lg:inline-block" /> leicht gemacht
            </h1>
            <p className="sm:text-lg mb-8 leading-relaxed">{data.sanityLevelOne.intro_content}
            </p>
          </div>
          <div className="lg:max-w-lg lg:w-full md:w-1/2 w-5/6">
            <Image {...data.sanityLevelOne.image} width={450} style={{
              width: '100%',
              objectFit: 'cover',
              opacity: '1',
              transition: 'opacity 500ms ease 0s'
            }} alt="E-Commerce leicht gemacht" />
          </div>
        </div>
      </section>



      <section className="text-gray-600 body-font">
        <div className="container  max-w-7xl px-5 pt-48 mx-auto ">
          <h2 className="sm:text-2xl text-2xl text-gray-900 mb-4 font-medium title-font text-center sm:align-baseline ">Bestens vorbereitet</h2>
          <p className="leading-relaxed text-base sm:text-lg">
            Für ein erfolgreiches E-Commerce Projekt ist das perfekte Zusammenspiel unterschiedlicher Disziplinen unverzichtbar. Neben der technischen Infrastruktur wollen zahlreiche vor- und nachgelagerte Prozessketten optimiert werden, alles unter Berücksichtigung der strategischen Ausrichtung und finanziellen Voraussetzungen:
          </p>
        </div>
      </section>


      <section className="text-gray-600 body-font">
        <div className="container max-w-7xl px-5 pt-48 mx-auto flex flex-wrap justify-items-center">
          <div className="md:w-2/5 mb-3 grow md:grow-0 flex ">
            <div>
              <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="current" className="w-6 h-6 stroke-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z" />
                </svg>
              </div>


            </div>
            <div>
              <h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Eigener Online Shop</h2>
            </div>
          </div>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base sm:text-lg">
              Mit dem eigenen Onlineshop ins E-Commerce Geschäft einsteigen: Während auf Marktplätzen und Drittplattformen Ihre Produkte oft direkt neben den Angeboten Ihrer Mitbewerber dargestellt werden, müssen Sie im eigenen Online Shop die Bühne mit niemandem teilen. Zudem genießen Sie in vielerlei Hinsicht größere Freiheit und Unabhängigkeit gegenüber dem Handel auf Online-Marktplätzen. Große Marktplätze wie z.B. Amazon haben oft ein kompliziertes und teilweise intransparentes Regelwerk. Angefangen von der Darstellung Ihrer Produkte über die Anforderungen an Produktdaten bis zu Eingriffen in die Kommunikation mit Kunden. Beim eigenen Online Shop haben Sie die Hoheit über Präsentation und Kundenansprache.
            </p>
            <div className="flex md:mt-4 mt-6">
              <a class="text-primary inline-flex items-center ">Dienstleistungen Onlineshops
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>



      <section className="text-gray-600 body-font">
        <div className="container  max-w-7xl px-5 pt-48 mx-auto flex flex-wrap justify-items-center">
          <div className="md:w-2/5 mb-3 grow md:grow-0 flex">
            <div>
              <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z" />
                  <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
              </div>
            </div>
            <div>
              <h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Welches Shopsystem? </h2>
            </div>
          </div>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base sm:text-lg">
              Die Wahl des korrekten Shopsystems für Ihren Online Shop ist eine Entscheidung von größter Tragweite für den zukünftigen Erfolg Ihres Shops. Die Auswahl an ernstzunehmenden Shopsystemen ist mittlerweile recht groß, so dass für die meisten Anwendungsfälle auch eine passende Lösung existiert. Viele Agenturen und Lösungsanbieter beraten nicht unbedingt plattformunabhängig, sondern versuchen die Shopsoftware zu verkaufen, für die sie zufällig zertifiziert sind ohne Pro und Contra unterschiedlicher Alternativen abzuwägen.
              Sparen Sie sich Ärger und unnötige Kosten, lassen Sie sich bei der Auswahl des Shopsystems für Ihren Online Shop von uns beraten. Wir sind nicht an eine einzelne Plattform gebunden und verfügen über weitreichende Expertise zu allen gängigen Shopsystemen und finden gemeinsamen mit Ihnen die optimale Plattform für Sie.
            </p>
            <div className="flex md:mt-4 mt-6">
              <a class="text-primary inline-flex items-center ">Mehr zur Wahl des Shopsystems
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>



      <section className="text-gray-600 body-font">
        <div className="container px-5  max-w-7xl pt-48 mx-auto flex flex-wrap justify-items-center">
          <div className="md:w-2/5 mb-3 grow md:grow-0 flex">
            <div>
              <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 stroke-black">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 01-.825-.242m9.345-8.334a2.126 2.126 0 00-.476-.095 48.64 48.64 0 00-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0011.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155" />
                </svg>
              </div>
            </div>
            <div>
              <h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Online Marktplätze</h2>
            </div>
          </div>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base sm:text-lg">Online Marktplätze eröffnen nicht nur bereits etablierten E-Commerce Akteuren den Zugang zu einer Vielzahl an neuen Käuferschichten und geographischen Märkten. Besonders auch Neueinsteiger können von den Vorteilen profitieren, die Marktplatz Plattformen bieten. Im Vergleich zum eigenen Online Shop ist der technische Aufwand für den Start teilweise deutlich geringer, und aufgrund der bisweilen umfangreichen Käuferschutzmaßnahem vertrauen viele Kunden beim Marktplatzeinkauf auch kleinen oder unbekannten Anbietern.
            </p>
            <div className="flex md:mt-4 mt-6">
              <a class="text-primary inline-flex items-center ">Marktplatz Dienstleistungen
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container  max-w-7xl px-5 pt-48 mx-auto flex flex-wrap justify-items-center">
          <div className="md:w-2/5 mb-3 grow  md:grow-0 flex ">
            <div className="">
              <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                </svg>
              </div>
            </div>






            <div className="">
              <h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Werbung und Marketing</h2>
            </div>
          </div>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base sm:text-lg">
              Ob für den eigenen Online Shop oder für Präsenzen auf Schlüsselmarktplätzen: Mit Werbung lässt sich die Sichtbarkeit erhöhen und der Umsatz steigern. Klickbasierte Kampagnen bei Google, Facebook, Amazon & Co. sind ein wirksames Werkzeug für Onlinehändler aller Kategorien. Damit Ihr Werbebudget optimal platziert wird und Früchte trägt, ist eine sorgfältige Planung und systematische Umsetzung unverzichtbar. Mit unserer langjährigen Erfahrung in der Konzeption und Pflege klickbasierter Werbekampagnen verhelfen wir auch Ihnen zu einer größeren Reichweite und Umsatzwachstum.
            </p>
            <div className="flex md:mt-4 mt-6">
              <a class="text-primary inline-flex items-center ">Werbung und Marketing Dienstleistungen
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-2" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="text-gray-600 body-font">
        <div className="container  max-w-7xl px-5 pt-48 mx-auto flex flex-wrap justify-items-center">
          <div className="md:w-2/5 mb-3 grow  md:grow-0 flex ">
            <div className="">
              <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                </svg>
              </div>
            </div>

            <div className="">
              <h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Daten und Content</h2>
            </div>
          </div>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base sm:text-lg">
              Da Ihre Kunden beim Einkauf im Internet die Waren nicht aus- beziehungsweise anprobieren oder anfassen können, ist eine ausführliche und aussagekräftige Produktbeschreibung mit ansprechenden Fotos als Informationsquelle und Kaufanreiz besonders wichtig.
              Die Basis hierfür sind in vielen Szenarien korrekte und vollständige Produktdaten. Während manche Lieferanten vorbildliche Produktdaten bereitstellen, ist häufig eine Bearbeitung und Anreicherung der vorliegenden Produktdaten notwendig.<br />
              Wir haben in den vergangenen Jahren für unsere Kunden Tausende von zielgruppengerechten Produktbeschreibungen inklusive Bildbearbeitung für eine Vielzahl an Shop- und Marktplatzformaten angefertigt.
              Sprechen Sie mit uns, wie Sie Ihre Ausgangsdaten sowie die Produktpräsentation in Wort und Bild optimieren können.
            </p>
          </div>
        </div>
      </section>

      <section className="text-gray-600 body-font">
        <div className="container  max-w-7xl px-5 py-48 mx-auto flex flex-wrap justify-items-center">
          <div className="md:w-2/5 mb-3 grow  md:grow-0 flex ">
            <div className="">
              <div className="hidden mx-auto w-9 h-9 sm:flex sm:inline-flex items-center justify-center rounded-full bg-primary-light text-indigo-500 mb-4 flex-shrink-0 sm:mr-3">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 stroke-black">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z" />
                </svg>
              </div>
            </div>






            <div className="">
              <h2 className="sm:text-2xl text-2xl text-gray-900 font-medium title-font mb-3 inline sm:align-baseline ">Logistik und Versand</h2>
            </div>
          </div>
          <div className="md:w-3/5 md:pl-6">
            <p className="leading-relaxed text-base sm:text-lg">
              Damit Ihre Produkte auch bei hohem Bestellaufkommen rechtzeitig beim Kunden eintreffen, müssen Lagerhaltung, Bestellabwicklung und Versand perfekt aufeinander abgestimmt sein.
              Zusammen mit dem Retourenmanagement sind diese miteinander verzahnten Prozesse besonders wichtig für die Kundenzufriedenheit, werden dennoch häufig unterschätzt oder wenig effizient gestaltet.
              Sprechen Sie mit uns, wie wir Ihre Logistik für die Anforderungen im Onlinehandel fit machen können.
            </p>
          </div>
        </div>
      </section>







      <section id="contacts" className="text-gray-50 body-font bg-dark bg-center bg-wavy bg-no-repeat bg-cover">
        <div className="container  max-w-7xl px-5 py-24 mx-auto flex flex-col mb-24 md:mb-64">
          <div className="lg:w-4/6 mx-auto">
            <div className="flex flex-col sm:flex-row mt-10">
              <ContactDetails />
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-primary sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left ">
                <p className="leading-relaxed text-lg mb-4 text-white">Sind Sie bereit für Ihr E-Commerce Projekt? Wir beraten plattformunabhängig mit Fokus auf Ihre individuellen Ziele und Anforderungen. Sichern Sie sich noch heute Ihr unverbindliche Erstgespräch. Mein Team und ich freuen uns auf Sie!  </p>
                <a className="cursor-pointer text-primary inline-flex items-center" href="mailto:hallo@digitscom.de?subject=Unverbindliches Erstgespräch: E-Commerce Einstieg&body=Guten Tag Herr Kurz,%0D%0A%0D%0A
            ich interessiere mich für eine kostenlose Erstberatung zum Thema Einstieg in den Onlinehandel.%0D%0A%0D%0A%0D%0ABitte nehmen Sie mit mir für eine kurzfristige Terminvereinbarung Kontakt auf.%0D%0A(Untenstehende Angaben bitte vervollständigen.)%0D%0A%0D%0A%0D%0AName:%0D%0AFirma:%0D%0ATel.:%0D%0AE-Mail:%0D%0A">Jetzt Erstgespräch sichern
                  <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" className="w-4 h-4 ml-2" viewBox="0 0 24 24">
                    <path d="M5 12h14M12 5l7 7-7 7"></path>
                  </svg>
                </a>
                <div className="mark">


                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Topics />

      <Footer />
    </>
  )
}

export default ecommerce;

export const Head = ({ data }) => {
  return (
    <>

      {/* <meta name="description" content="This is an example of a meta description. This will often show up in search results."/> */}
      {/* <title> </title> */}
      <SEO />
    </>
  )
}

export const dataWebDev = graphql`
query MyEcommerceQuery {
  sanityLevelOne(id: {eq: "-2154ead3-e5c4-5f2d-9dda-51a81590315f"}) {
    id
    image {
        ...ImageWithPreview
    }
    intro_content
    intro_title
    meta_description
    title
  }
}
`